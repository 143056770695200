import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "explore",
  initialState: {
    isLoading: false,
    projects: [],
  },
  reducers: {
    loading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },

    successLoad: (state, action) => {
      return {
        ...state,
        isLoading: false,
        projects: action.payload,
      };
    },
  },
});

export const { loading, successLoad } = projectSlice.actions;

export default projectSlice.reducer;
