import { Outlet } from "react-router-dom";
import "../App.css";
import "bulma/css/bulma.min.css";
import "../all.css";
import "./layout.css";

export const ProjectLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
