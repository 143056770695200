import { Link } from "react-router-dom";
import "./footer.css";

export const Footer = () => (
  <footer className="footer footerwrapper">
    <div className=" has-text-centered">
      <p>MyWishes.pro</p>
    </div>
  </footer>
);
