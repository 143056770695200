// https://blog.logrocket.com/how-to-build-tab-component-react/
import React, { useState } from "react";
import "./tab-list.css";

export const TabItem = ({ label, children }) => (
  <div
    className="tab-panel"
    role="tabpanel"
    aria-labelledby={`tab-${label}`}
    id={`panel-${label}`}
  >
    <>{children}</>
  </div>
);

export const TabList = ({ children, activeTabIndex = 0 }) => {
  const tabs = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && child.type === TabItem
  );

  const [activeTab, setActiveTab] = useState(activeTabIndex);

  return (
    <>
      <div className="tabs">
        <ul role="tablist">
          {tabs.map((tab, index) => (
            <li
              key={`tab-${index}`}
              className={activeTab === index ? "is-active" : undefined}
              onClick={() => setActiveTab(index)}
            >
              <a>{tab.props.label}</a>
            </li>
          ))}
        </ul>
      </div>
      {tabs[activeTab]}
    </>
  );
};
