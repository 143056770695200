import { toast } from "react-toastify";

export const toastError = (message) =>
  toast.error(message, {
    position: "bottom-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  });

export const toastInfo = (message) =>
  toast.info(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  });

export const toastWarning = (message) =>
  toast.warn(message, {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  });

export const toastImportant = (message, link) =>
  toast.dark(message, {
    position: "top-right",
    autoClose: 30000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  });

export const toastSuccess = (message) =>
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    theme: "colored",
  });
