import { Footer } from "../../layouts/footer/Footer";
import { Header } from "../../layouts/header/Header";
import "./home.css";

const Home = () => {
  return (
    <>
      <Header />
      <div className="main-goal-wrap">
        <div className="main-goal">
          <h1>Создай поздравления, которые порадуют ваших друзей и близких</h1>

          {/* <p>
          С помощью наших инструментов вы можете создать уникальные
          фотопоздравления, которые поразят и порадуют ваших близких.
        </p> */}

          <p></p>
        </div>
      </div>
      {/* <div className="main-examples">
        <h2>Примеры работ:</h2>
        Примеры работ: Посмотрите, как легко и красиво можно создать
        фотопоздравления с нашими инструментами: <br /> День рождения: Веселые и
        красочные поздравления, которые поднимут настроение имениннику. <br />{" "}
        Свадьба: Романтичные и элегантные пожелания, которые подчеркнут
        значимость момента. <br /> Праздники: Оригинальные и яркие поздравления
      </div> */}
      <div className="columns functional">
        <div className="column">
          Создавайте поздравления или пожелания всего за несколько кликов.
        </div>
        <div className="column">
          Добавляйте свои фото, комментарии или используйте уникальные элементы
          дизайна. Пусть каждое поздравление будет особенным.
        </div>
        <div className="column">
          Наши шаблоны подходят для всех случаев — от дней рождения и свадеб до
          праздников и юбилеев.
        </div>
      </div>
      <div className="columns functional">
        <div className="column">
          Создавайте и делитесь своими поздравлениями в любое время и в любом
          месте. Наш сайт доступен на всех устройствах.
        </div>
        <div className="column">
          Загружайте и храните свои фотографии в нашем защищенном облаке, чтобы
          использовать их в любое время.
        </div>
      </div>
      <div className="main-examples">
        <h2>Как это работает</h2>
        <div className="columns ">
          <div className="column description-step">
            <div className="step-name">
              <div className="step">1</div> Зарегистрируйтесь
            </div>
            <p>
              Cоздайте учетную запись, чтобы сохранить свои проекты и
              фотографии.
            </p>
          </div>
          <div className="column description-step">
            <div className="step-name">
              <div className="step">2</div> Выберите шаблон
            </div>
            <p>Ознакомьтесь с нашими коллекциями и выберите понравившийся.</p>
          </div>
          <div className="column description-step">
            <div className="step-name">
              <div className="step">3</div> Персонализируйте
            </div>
            <p>
              Добавьте свой комментарии, подписи, стикеры. Оформите расположение
              слайдов
            </p>
          </div>
          <div className="column description-step">
            <div className="step-name">
              <div className="step">4</div> Поделитесь
            </div>
            <p>
              Отправьте ваше поздравление в любом месседжере, по электронной
              почте или в социальных сетях.
            </p>
          </div>
        </div>
        <br /> <br />
        {/* Свяжитесь с нами: Если у вас есть вопросы или предложения, наша команда
        поддержки всегда готова помочь. Напишите нам или позвоните, и мы сделаем
        все возможное, чтобы ваше поздравление было идеальным. */}
      </div>

      <Footer />
    </>
  );
};

export default Home;
