import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "projects",
  initialState: {
    isUpdated: false,
    isLoading: false,
    isPending: false,
    projects: [],
    operationId: null,
  },
  reducers: {
    loading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    pending: (state, action) => {
      return {
        ...state,
        isPending: action.payload,
      };
    },
    successLoad: (state, action) => {
      return {
        ...state,
        isLoading: false,
        projects: action.payload,
        isUpdated: true,
      };
    },
    successRemove: (state, action) => {
      return {
        ...state,
        isPending: false,
        projects: state.projects.filter((it) => it.id !== action.payload),
      };
    },
    projectsNotUpdated: (state) => {
      return {
        ...state,
        isUpdated: false,
      };
    },
  },
});

export const {
  loading,
  pending,
  successLoad,
  successOperation,
  successRemove,
  projectsNotUpdated,
} = projectSlice.actions;

export default projectSlice.reducer;
