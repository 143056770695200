import React from "react";
import { useField } from "formik";

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="control">
      <label htmlFor={props.name}>{label}</label>
      <input
        className="input"
        //id="firstName"
        //type="text"
        ///{...meta.getFieldProps("firstName")}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};
