import { API_URL } from "../constants";
import { toastError } from "../services/toast/toast";

export const sendRequest = async (url, method, body) => {
  try {
    const response = await fetch(`${API_URL}${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (response.status === 401) {
      window.location.href = "/login";
    }

    if (![200, 204, 404].includes(response.status)) {
      console.log(response);
      toastError("Error");
      return { isSuccess: false, data: response.status };
    }

    const data = await response.json();
    return { isSuccess: true, data };
  } catch (e) {
    toastError("Error");
    return { isSuccess: false, error: { message: e } };
  }
};
