import "./modal.css";

export const Modal = ({ title, width = 50, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const className = `modal ${isOpen ? "is-active" : ""}`;
  return (
    <div className={className}>
      <div className="modal-background" onClick={onClose}></div>
      <div className={`modal-card width-${width}`}>
        <header className="modal-header">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">{children}</section>
      </div>
    </div>
  );
};
