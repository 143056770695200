import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "creator",
  initialState: {
    isPending: false,
    newProjectId: null,
  },
  reducers: {
    pending: (state, action) => {
      return {
        ...state,
        isPending: action.payload,
      };
    },
    successCreated: (state, action) => {
      return {
        ...state,
        isPending: false,
        newProjectId: action.payload,
      };
    },
    cleanProjectId: (state, action) => {
      return {
        ...state,
        newProjectId: null,
      };
    },
  },
});

export const { pending, successCreated, cleanProjectId } = slice.actions;

export default slice.reducer;
