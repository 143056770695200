import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { GenerateModal } from "./components/generate-modal";
import { TabItem, TabList } from "../../components/tab-list/tab-list";
import { Link, Navigate } from "react-router-dom";
import { cleanProjectId } from "../../store/creator/slice";
import { projectsNotUpdated } from "../../store/projects/slice";
import templates from "../../json/templates.json";
import "./create-project.css";

export const CreateProject = ({
  newProjectId,
  isPending,
  createProject,
  generateProject,
}) => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch();
  const createEmptyProject = async () => {
    const params = {
      version: 1,
      pages: [
        {
          slides: [
            {
              background: { color: "#fff" },
              content: [],
            },
            {
              background: { color: "#fff" },
              content: [],
            },
          ],
        },
      ],
    };
    createProject(params);
  };

  if (newProjectId) {
    dispatch(cleanProjectId());
    dispatch(projectsNotUpdated());
    return <Navigate to={`/edit/${newProjectId}`} />;
  }

  return (
    <div className="templates">
      <div className="templates-main">
        <div className="is-flex-grow-1">
          <button className="is-flex">
            <Link
              className="box from-gallery"
              disabled={isPending}
              to="/create/from-gallery"
            >
              Использовать галлерею
            </Link>

            <button
              className="box from-gallery"
              disabled={isPending}
              onClick={createEmptyProject}
            >
              Создать пустой проект
            </button>
          </button>
          <TabList>
            <TabItem label="Персоональные">
              {
                <div className="templates-list">
                  {templates.personal.map((template) => (
                    <button
                      className="template-group box ready"
                      onClick={() => setSelected(template)}
                    >
                      <img src={`/stickers/${template.icon}`} />
                      {template[i18n.language]}
                    </button>
                  ))}
                </div>
              }
            </TabItem>
            <TabItem label="Международные">
              <div className="templates-list">
                {templates.international.map((template) => (
                  <button
                    className="template-group box ready"
                    onClick={() => setSelected(template)}
                  >
                    <img src={`/stickers/${template.icon}`} />
                    {template[i18n.language]}
                  </button>
                ))}
              </div>
            </TabItem>
            <TabItem label="Для семьи и друзей">
              <div className="templates-list">
                {templates.family.map((template) => (
                  <button
                    className="template-group box ready"
                    onClick={() => setSelected(template)}
                  >
                    <img src={`/stickers/${template.icon}`} />
                    {template[i18n.language]}
                  </button>
                ))}
              </div>
            </TabItem>
            <TabItem label="Без повода">
              <div className="templates-list">
                {templates.common.map((template) => (
                  <button
                    className="template-group box ready"
                    onClick={() => setSelected(template)}
                  >
                    <img src={`/stickers/${template.icon}`} />
                    {template[i18n.language]}
                  </button>
                ))}
              </div>
            </TabItem>
          </TabList>
          {/* asd таб: календарные(международные) . для семья и друзей.
          персоональные поводы. без повода .
          <h1>{t("templates.use-template")}</h1>
          <div className="templates-list">
            {templateList
              .filter((t) => t.ready)
              .map((template) => (
                <button
                  className="template-group box ready"
                  onClick={() => setSelected(template.type)}
                >
                  <img src={`/stickers/${template.icon}`} />
                  {t(`templates.group.${template.type}`)}
                </button>
              ))}
          </div>
          <h2>{t("templates.coming-soon")}</h2>
          <div className="templates-list">
            {templateList
              .filter((t) => !t.ready)
              .map((template) => (
                <div className="template-group box soon">
                  <img src={`/stickers/${template.icon}`} />
                  {t(`templates.group.${template.type}`)}
                </div>
              ))}
          </div> */}
        </div>
      </div>
      <GenerateModal
        template={selected}
        onClose={() => setSelected(null)}
        generateProject={generateProject}
        isPending={isPending}
      />
      :
    </div>
  );
};
