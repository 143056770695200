import { createContext, useState, useEffect } from "react";
import * as auth from "./auth";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = async () => {
    try {
      const user = await auth.getUser();
      // console.log("current user", user);
      setUser(user);
    } catch (err) {
      setUser(null);
    }
  };

  useEffect(() => {
    getCurrentUser()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, []);

  const signIn = async (username, password) => {
    await auth.signIn(username, password);
    await getCurrentUser();
  };
  const signOut = async () => {
    await auth.signOut();
    setUser(null);
    window.location.replace("/");
  };

  const authValue = {
    user,
    isLoading,
    signOut,
    signIn,
  };
  if (isLoading) return null;

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
