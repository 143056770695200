import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "photos",
  initialState: {
    isPending: false,
    isFilesLoading: true,
    isFoldersLoading: true,
    isFoldersUpdated: false,
    folders: [{ name: "Главная", id: "main", files: [], isUpdated: false }],
    folderIndex: 0,
  },
  reducers: {
    filesLoading: (state) => {
      return {
        ...state,
        isFilesLoading: true,
      };
    },
    filesPending: (state) => {
      return {
        ...state,
        isPending: true,
      };
    },
    updateIndex: (state, action) => {
      return {
        ...state,
        folderIndex: action.payload,
      };
    },
    addFolder: (state, action) => {
      return {
        ...state,
        folders: [
          ...state.folders,
          {
            name: action.payload,
            id: action.payload,
            files: [],
            isUpdated: false,
          },
        ],
        folderIndex: state.folders.length,
      };
    },
    removeFolder: (state, action) => {
      return {
        ...state,
        folders: state.folders.filter((folder) => folder.id !== action.payload),
        folderIndex: 0,
        isFilesLoading: false,
      };
    },
    successFilesLoad: (state, action) => {
      return {
        ...state,
        folders: state.folders.map((folder) =>
          folder.id === action.payload.folder
            ? {
                ...folder,
                isUpdated: true,
                files: action.payload.files,
              }
            : folder
        ),
        isFilesLoading: false,
        isPending: false,
      };
    },
    successFoldersLoad: (state, action) => {
      return {
        ...state,
        folders: [
          state.folders[0],
          ...action.payload.map((folder) => ({
            name: folder.name,
            id: folder.id,
            files: [],
            isUpdated: false,
          })),
        ],
        isFoldersLoading: false,
        isFoldersUpdated: true,
      };
    },
  },
});

export const {
  filesLoading,
  filesPending,
  addFolder,
  removeFolder,
  successFilesLoad,
  successFoldersLoad,
  updateIndex,
} = slice.actions;

export default slice.reducer;
