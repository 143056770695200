import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "archive",
  initialState: {
    isPending: false,
    isLoading: true,
    texts: [],
    images: [],
  },
  reducers: {
    loading: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    pending: (state) => {
      return {
        ...state,
        isPending: true,
      };
    },
    successFilesLoad: (state, action) => {
      return {
        ...state,
        images: action.payload.files,
        isLoading: false,
        isPending: false,
      };
    },
    successTextsLoad: (state, action) => {
      return {
        ...state,
        texts: action.payload.texts,
        isLoading: false,
      };
    },
  },
});

export const { loading, pending, successFilesLoad, successTextsLoad } =
  slice.actions;

export default slice.reducer;
