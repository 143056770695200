import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createProject, generateProject } from "../../store/creator/actions";
import { CreateProject } from "./create-project";

const mapStateToProps = (state) => ({
  ...state.creator,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProject,
      generateProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
