import { lazy, Suspense } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { NotFoundPage } from "./pages/notFound/NotFoundPage";
import { SiteLayout } from "./layouts/SiteLayout";
import { ProjectLayout } from "./layouts/ProjectLayout";
import ConfirmUserPage from "./pages/confirmUserPage";
import Create from "./pages/create-project";
import HomePage from "./pages/home/home";

const CreateFromGalleryPage = lazy(() => import("./pages/create-from-gallery"));

const PhotosPage = lazy(() => import("./pages/photos/photosContainer"));
const ProjectsPage = lazy(() => import("./pages/projects/ProjectsContainer"));
const LoginPage = lazy(() => import("./pages/loginPage"));
const SliderViewPage = lazy(() => import("./pages/slider/SliderViewContainer"));
const SliderEditPage = lazy(() => import("./pages/slider/SliderEditContainer"));
const ExplorePage = lazy(() => import("./pages/explore"));
// const TemplatesPage = lazy(() => import("./pages/templates"));
const ArchiveImagesPage = lazy(() => import("./pages/ai-archive-images"));
const ArchiveTextsPage = lazy(() => import("./pages/ai-archive-texts"));

export default function App() {
  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/" element={<SiteLayout />}>
          <Route path="workspace" element={<ProjectsPage />} />
          <Route path="photos" element={<PhotosPage />} />

          <Route path="ai-images" element={<ArchiveImagesPage />} />
          <Route path="ai-texts" element={<ArchiveTextsPage />} />
          <Route path="explore" element={<ExplorePage />} />
          {/* <Route path="templates" element={<TemplatesPage />} /> */}

          <Route path="audio" element={<>audio</>} />
          <Route path="calendar" element={<>Calendar</>} />
          <Route path="statistic" element={<>Calendar</>} />
          <Route path="statistic" element={<>Calendar</>} />

          <Route path="login" element={<LoginPage />} />
          <Route path="confirm" element={<ConfirmUserPage />} />

          <Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} />

          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Route>

        <Route path="/edit/:id" element={<ProjectLayout />}>
          <Route index element={<SliderEditPage />} />
        </Route>

        <Route path="/create" element={<SiteLayout />}>
          <Route index element={<Create />} />
          <Route
            path="/create/from-gallery"
            element={<CreateFromGalleryPage />}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="/:id" element={<SliderViewPage />} />
      </Routes>
    </Suspense>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
            share across all the pages on your site, like navigation. */}
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/nothing-here">Nothing Here</Link>
          </li>
        </ul>
      </nav>

      <hr />

      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}
