import { sendRequest } from "../../api/sendRequest";
import { pending, successCreated } from "./slice";

export const createProject = (body) => async (dispatch) => {
  try {
    dispatch(pending(true));
    const { isSuccess, data } = await sendRequest("/projects", "POST", body);
    if (isSuccess) {
      dispatch(successCreated(data));
    }
  } catch {
    dispatch(pending(false));
  }
};

export const generateProject = (body) => async (dispatch) => {
  dispatch(pending(true));
  const { isSuccess, data, error } = await sendRequest(
    "/generate",
    "POST",
    body
  );
  if (isSuccess) {
    dispatch(successCreated(data));
  } else {
    dispatch(pending(false));
  }
};
