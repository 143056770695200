import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../services/auth/AuthContext";
import { SiDask } from "react-icons/si";

import "./header.css";

export const Header = () => {
  const { user, signOut } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav
      className="navbar main-header"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className=" logo" to="/">
          <SiDask /> MyWishes.pro
        </Link>

        <a
          role="button"
          className={isMenuOpen ? "navbar-burger is-active" : "navbar-burger"}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => setIsMenuOpen((s) => !s)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarBasicExample"
        className={isMenuOpen ? "navbar-menu is-active" : "navbar-menu"}
      >
        <div className="navbar-start">
          {user ? (
            <>
              <Link className="navbar-item" to="/workspace">
                {t("header.my_projects")}
              </Link>
              <Link className="navbar-item" to="/photos">
                {t("header.photo_albums")}
              </Link>
              <Link className="navbar-item" to="/create">
                {t("header.templates")}
              </Link>
              {user.Username === "64a814a8-e0a1-70cb-f31c-c0ad71cdf8d1" ? (
                <>
                  <Link className="navbar-item" to="/ai-images">
                    {t("header.ai-images")}
                  </Link>
                  <Link className="navbar-item" to="/ai-texts">
                    {t("header.ai-text")}
                  </Link>
                </>
              ) : null}
            </>
          ) : (
            <>
              <Link className="navbar-item" to="/explore">
                {t("header.explore")}
              </Link>
              <Link className="navbar-item" to="/templates">
                {t("header.templates")}
              </Link>
              {/* <Link className="navbar-item" to="/about">
                {t("header.about")}
              </Link> */}
              {/* <Link className="navbar-item" to="/pricing">
                Pricing
              </Link> */}
            </>
          )}
        </div>

        <div className="navbar-end">
          <button type="button" onClick={() => changeLanguage("ru")}>
            ru
          </button>
          <button type="button" onClick={() => changeLanguage("en")}>
            en
          </button>
          {user ? (
            <>
              <Link className="navbar-item" to="/explore">
                {t("header.explore")}
              </Link>
            </>
          ) : null}

          <div className="navbar-item">
            {user ? (
              <div className="buttons">
                <a className="button is-light" onClick={signOut}>
                  {t("header.logout")}
                </a>
              </div>
            ) : (
              <div className="buttons">
                <Link className="button is-light" to="/login">
                  {t("header.login")}
                </Link>
                <Link className="button is-warning" to="/login">
                  {t("header.try")}
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
