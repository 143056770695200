import { createSlice } from "@reduxjs/toolkit";

const setIds = (pages, lastId) => {
  let elementId = lastId;
  let slideId = lastId;

  const getElementId = () => {
    elementId = elementId + 1;
    return elementId;
  };

  const getSlideId = () => {
    slideId = slideId + 1;
    return slideId;
  };

  return {
    pages: pages.map((page) => ({
      ...page,
      slides: page.slides.map((slide) => ({
        ...slide,
        // TODO: remove
        slideId: getSlideId(),
        content: slide.content.map((element) => ({
          ...element,
          id: getElementId(),
        })),
      })),
    })),
    lastId: elementId,
  };
};

const slice = createSlice({
  name: "slider",
  initialState: {
    lastId: 0,
    projectId: null,
    isLoading: false,
    isNotFound: false,
    pages: [],
    // layouts: [],
  },
  reducers: {
    resetSlider: () => {
      return {
        lastId: 0,
        projectId: null,
        isLoading: false,
        isNotFound: false,
        pages: [],
      };
    },
    successLoad: (state, action) => {
      const { pages, lastId } = setIds(action.payload.pages, state.lastId);

      return {
        isLoading: false,
        projectId: action.payload.id,
        pages,
        lastId,
        settings: action.payload.settings,
        layouts: action.payload.layouts || [],
        template: action.payload.template,
      };
    },

    errorLoad: (state) => {
      return {
        ...state,
        isLoading: false,
        isNotFound: true,
      };
    },

    loading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },

    // fix
    load: (state, action) => {
      const { slides, lastId } = setIds(action.payload.slides, state.lastId);
      return {
        ...state,
        isLoading: false,
        slides,
        lastId,
      };
    },
    clear: (state) => {
      return {
        ...state,
        isLoading: false,
        lastId: 0,
        slides: [{ content: [] }],
      };
    },
    addElement: (state, action) => {
      // const slide = state.slides[action.payload.slideIndex];
      const newId = state.lastId + 1;

      return {
        ...state,
        lastId: newId,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                slides: page.slides.map((slide, slideIndex) =>
                  slideIndex === action.payload.slideIndex
                    ? {
                        ...slide,
                        content: [
                          ...slide.content,
                          { ...action.payload.element, id: newId },
                        ],
                      }
                    : slide
                ),
              }
            : page
        ),
      };
    },
    addSlide: (state, action) => {
      return {
        ...state,
        pages: [
          ...state.pages,
          { slides: [{ background: { color: "#fff" }, content: [] }] },
        ],
      };
    },

    updateSlideAttribute: (state, action) => {
      const updatedSlide = {
        ...state.slides[action.payload.slideIndex],
        ...action.payload.attribute,
      };

      return {
        ...state,
        slides: state.slides.map((it, pos) =>
          pos === action.payload.slideIndex ? updatedSlide : it
        ),
      };
    },

    updateTemplate: (state, action) => {
      return {
        ...state,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                template: action.payload.template,
              }
            : page
        ),
      };
    },

    updateSettings: (state, action) => {
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      };
    },
    // TODO... refactor
    replaceSlide: (state, action) => {
      const pages = JSON.parse(JSON.stringify(state.pages));

      const fromSlide =
        pages[action.payload.from.pageIndex].slides[
          action.payload.from.slideIndex
        ];

      const pageFrom = {
        ...pages[action.payload.from.pageIndex],
        template: undefined,
        slides: pages[action.payload.from.pageIndex].slides.filter(
          (_, index) => index !== action.payload.from.slideIndex
        ),
      };
      pages[action.payload.from.pageIndex] = pageFrom;

      const pageTo = {
        ...pages[action.payload.to.pageIndex],
        template: undefined,
        slides: pages[action.payload.to.pageIndex].slides
          .toSpliced(action.payload.to.slideIndex, 0, fromSlide)
          .map((i) => i),
      };

      pages[action.payload.to.pageIndex] = pageTo;
      const fPages = pages.filter((p) => p.slides.length > 0);
      return {
        ...state,
        pages: fPages,
      };
    },

    removeSlide: (state, action) => {
      return {
        ...state,
        pages: state.pages
          .map((page, pageIndex) =>
            pageIndex === action.payload.pageIndex
              ? {
                  ...page,
                  slides: page.slides.filter(
                    (_, slideIndex) => slideIndex !== action.payload.slideIndex
                  ),
                }
              : page
          )
          .filter((p) => p.slides.length > 0),
      };
    },
    removeBackground: (state, action) => {
      const slide = { ...state.slides[action.payload] };
      slide.background = undefined;

      return {
        ...state,
        slides: state.slides.map((it, pos) =>
          pos === action.payload ? slide : it
        ),
      };
    },
    updateElement: (state, action) => {
      const slide =
        state.pages[action.payload.pageIndex].slides[action.payload.slideIndex];

      const updatedSlide = {
        ...slide,
        content: slide.content.map((it) =>
          it.id === action.payload.element.id
            ? { ...it, ...action.payload.element }
            : it
        ),
      };

      return {
        ...state,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                slides: page.slides.map((slide, slideIndex) =>
                  slideIndex === action.payload.slideIndex
                    ? updatedSlide
                    : slide
                ),
              }
            : page
        ),
      };
    },

    removeElement: (state, action) => {
      return {
        ...state,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                slides: page.slides.map((slide, slideIndex) =>
                  slideIndex === action.payload.slideIndex
                    ? {
                        ...slide,
                        content: slide.content.filter(
                          (it) => it.id !== action.payload.elementId
                        ),
                      }
                    : slide
                ),
              }
            : page
        ),
      };
    },
    resetSlides: (state, action) => {
      const slides = state.pages.reduce(
        (acc, page) => [...acc, ...page.slides],
        []
      );

      const convertor = slides.reduce(
        (result, item) => {
          if (result.last.length > action.payload - 2) {
            result.pages.push({
              slides: [...result.last, item],
              template: undefined,
            });
            result.last = [];
          } else {
            result.last.push(item);
          }
          return result;
        },
        { pages: [], last: [] }
      );
      if (convertor.last.length > 0) {
        convertor.pages.push({
          slides: [...convertor.last],
          template: undefined,
        });
      }

      return {
        ...state,
        pages: convertor.pages,
      };
    },
    updateBackground: (state, action) => {
      const slide =
        state.pages[action.payload.pageIndex]?.slides[
          action.payload.slideIndex
        ];
      const updatedSlide = {
        ...slide,
        background: { ...action.payload.background },
      };
      return {
        ...state,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                slides: page.slides.map((slide, slideIndex) =>
                  slideIndex === action.payload.slideIndex
                    ? updatedSlide
                    : slide
                ),
              }
            : page
        ),
      };
    },
    replaceImage: (state, action) => {
      const slide =
        state.pages[action.payload.pageIndex]?.slides[
          action.payload.slideIndex
        ];
      const updatedSlide = {
        ...slide,
        background: { src: action.payload.src },
      };

      return {
        ...state,
        pages: state.pages.map((page, pageIndex) =>
          pageIndex === action.payload.pageIndex
            ? {
                ...page,
                slides: page.slides.map((slide, slideIndex) =>
                  slideIndex === action.payload.slideIndex
                    ? updatedSlide
                    : slide
                ),
              }
            : page
        ),
      };
    },
  },
});

export const {
  resetSlider,
  errorLoad,
  load,
  clear,
  addElement,
  removeElement,
  addSlide,
  removeSlide,
  removeBackground,
  updateSlideAttribute,
  updateSettings,
  updateElement,
  successLoad,
  loading,

  replaceSlide,
  resetSlides,
  updateTemplate,
  updateBackground,
  replaceImage,
} = slice.actions;

export default slice.reducer;
