import { configureStore } from "@reduxjs/toolkit";
import sliderReducer from "./slider/slice";
import projectsReducer from "./projects/slice";
import creatorReducer from "./creator/slice";
import photosReducer from "./photos/slice";
import exploreReducer from "./explore/slice";
import archiveReducer from "./archive/slice";

export default configureStore({
  reducer: {
    slider: sliderReducer,
    projects: projectsReducer,
    creator: creatorReducer,
    photos: photosReducer,
    explore: exploreReducer,
    archive: archiveReducer,
  },
});
