import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal } from "../../../components/modal";
import { TextInput } from "../../../components/text-input/text-input";
import { RadioInput } from "../../../components/radio-input/radio-input";

export const GenerateModal = ({
  template,
  onClose,
  generateProject,
  isPending,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      title={t("templates.generate-modal-title")}
      width={50}
      isOpen={template}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          projectName: "",
          size: "15",
          lang: i18n.language,
          style: "normal",
        }}
        validationSchema={Yup.object({
          projectName: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required(t("common.required")),
        })}
        onSubmit={(props) => {
          generateProject({ ...props, template: template.type });
        }}
      >
        <Form>
          <section className="modal-card-body1">
            <TextInput
              label={t("templates.project-name")}
              name="projectName"
              type="text"
            />
            {/* <RadioInput
              label={t("templates.generate-count")}
              name="size"
              data={[
                { label: "9", value: "9" },
                { label: "12", value: "12" },
                { label: "15", value: "15" },
                { label: "21", value: "21" },
              ]}
            /> */}
            <RadioInput
              label={t("templates.generate-lang")}
              name="lang"
              data={[
                { label: t("templates.english"), value: "en" },
                { label: t("templates.russian"), value: "ru" },
              ]}
            />
            <RadioInput
              label={t("templates.generate-style")}
              name="style"
              data={[
                { label: t("templates.generate-normal"), value: "normal" },
                { label: t("templates.generate-fun"), value: "fun" },
              ]}
            />

            <div className="buttons">
              <button
                className="button is-success"
                disabled={isPending}
                type="submit"
              >
                {t("templates.generate")}
              </button>
              <button className="button" onClick={onClose}>
                {t("common.cancel")}
              </button>
            </div>
          </section>
        </Form>
      </Formik>
    </Modal>
  );
};
