import React from "react";
import { Field, useField } from "formik";

export const RadioInput = ({ label, data, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="control">
      <label htmlFor={props.name}>{label}</label>
      <br />
      {data.map((i) => (
        <label>
          <Field type="radio" {...field} {...props} value={i.value} />
          {i.label}
        </label>
      ))}
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};
