import { useState, useEffect } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import "../App.css";
import "bulma/css/bulma.min.css";
import "../all.css";
import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";
import "./layout.css";

export const SiteLayout = () => {
  return (
    <>
      <Header />
      <main className="site-layout is-fullhd">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
